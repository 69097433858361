.markdown {
  @apply text-lg leading-relaxed;
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown blockquote {
  @apply my-6;
}

.markdown ul {
  @apply list-disc;
}

.markdown li {
  @apply ml-16
}

.markdown h2 {
  @apply lg:text-3xl text-2xl mt-12 mb-4 leading-snug;
}

.markdown h3 {
  @apply text-2xl mt-8 mb-4 leading-snug;
}