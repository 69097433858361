@tailwind base;
@tailwind components;
@tailwind utilities;

.main-navbar {
  position: sticky;
  left: 20px;
  right: 20px;
  top: 20px;
  width: 97%;
}

@media screen and (max-width: 760px) {
  .main-navbar {
    width: 90%;
  }

  .statistics{
    background-size: 0px;
  }
}

@media screen and (min-width: 820px) {
  .main-navbar {
    width: 95%;
  }
}

@media screen and (min-width: 1280px) {
  .main-navbar {
    width: 97%;
  }
}

.hero-highlighted-caption::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #86efac;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.service-gallery {
  width: 400px;
  max-width: 400px;
  height: 400px;
}

@media screen and (max-width: 640px) {
  .service-gallery {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 950px) {
  .service-gallery {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    height: 400px;
  }
}

.service-section-right {
  height: 400px;
  min-height: 400px;
}

@media screen and (max-width: 950px) {
  .service-section-right {
    min-height: 440px;
  }
}


.service-title {
  width: 800px;
}

.cta-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@keyframes rotateAnimation {

  0%,
  40% {
    transform: rotate(0deg);
  }

  60%,
  100% {
    transform: rotate(720deg);
  }
}

/* .header-logo {
  animation: rotateAnimation 7s ease-in-out infinite;
  animation-delay: 4s;
} */

.abstraction-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.about-hero-image {
  position: relative;
}

.about-hero-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(247,247,247);
  background: linear-gradient(90deg, rgba(247,247,247,0.5496323529411764) 0%, rgba(255,255,255,0) 100%);
  z-index: 2;
  width: 100%;
  height: 100%;
}
